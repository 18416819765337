<template>
  <div class="flex items-center">
    <NuxtLink to="/" class="pl-5 pt-8 pb-4">
      <svg height="8" viewBox="0 0 114 11" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M5.565 10.431c3.074 0 5.566-2.335 5.566-5.215S8.639 0 5.565 0C2.492 0 0 2.335 0 5.216c0 2.88 2.492 5.215 5.565 5.215zm19.327 0c3.073 0 5.565-2.335 5.565-5.215S27.965 0 24.892 0c-3.074 0-5.566 2.335-5.566 5.216 0 2.88 2.492 5.215 5.566 5.215zm83.481 0c3.108 0 5.627-2.335 5.627-5.215S111.481 0 108.373 0c-3.107 0-5.626 2.335-5.626 5.216 0 2.88 2.519 5.215 5.626 5.215z" fill="#fff"/>
        <path fill-rule="evenodd" clip-rule="evenodd" d="M94.674 5.216C94.674 2.337 92.179 0 89.108 0H44.095c-3.071 0-5.565 2.337-5.565 5.216 0 2.878 2.494 5.215 5.565 5.215h45.013c3.072 0 5.566-2.337 5.566-5.215z" fill="#fff"/>
      </svg>
    </NuxtLink>
  </div>
</template>

<script setup lang="ts">
const { isSuperadmin, userData } = useUserState()
</script>

<template>
  <v-list>
    <v-divider />
    <v-list-item v-if="isSuperadmin" title="Briefings" prepend-icon="$mdiListbox" to="/briefings" />
    <v-divider />
    <v-list-item title="Demos" prepend-icon="$mdiDashboard" to="/demostraciones" />
    <v-divider v-if="isSuperadmin || (!isSuperadmin && userData.refCompany?.bucket)" />
    <v-list-item v-if="isSuperadmin || (!isSuperadmin && userData.refCompany?.bucket)" title="Recomendaciones" prepend-icon="$mdiLightbulbOnOutline" to="/insights" />
    <v-divider />
    <v-list-item title="Características" prepend-icon="$mdiProgressCheck" to="/pricing" />
    <v-divider />
    <v-list-item active title="Contratar" variant="outlined" color="primary" rounded prepend-icon="$mdiFileDocumentEdit" to="/contact" />
    <v-divider />
    <v-list-item v-if="isSuperadmin" title="Analytics" prepend-icon="$mdiAnalytics" to="/analytics" />
    <v-divider v-if="isSuperadmin" />
  </v-list>
</template>
